import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"

import Ornament from "../components/common/Ornament"
import { StaticImage } from "gatsby-plugin-image"

function GasPage() {
  return (
    <PageLayout>
      <SEO keywords={[`Gås`, `Mårten Gås`, `2023`]} title="Boka Gås 2023" />
      <GasMeny />
    </PageLayout>
  )
}

export default GasPage

export function GasMeny() {
  return (
    <section
      id="gas-meny"
      className="flex flex-col justify-center items-center max-w-xl mx-auto px-3"
    >
      <fieldset className="border-cherry border-2 px-2 rounded-md text-center mx-auto menu-box">
        <legend>
          <h2 className="text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-8 bg-transparent">
            Gåsmeny 2024
          </h2>
        </legend>

        <div className="my-6 text-sm">
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Förrätt</h3>
            <p className="text-lg italic">
              Svartsoppa med gåskorv samt krås och äpple
            </p>
            <p className="text-sm font-bold">alt.</p>
            <p className="text-lg italic">
              Skogssvampsoppa med trattkantareller{" "}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Huvudrätt</h3>
            <p className="text-lg italic">
              Helstekt gås från Munkaljungby Rödkål, rosenkål, Österlen äpple,
              gåssås, sockerstekt potatis och plommon
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Efterrätt</h3>
            <p className="text-lg italic">Skånsk äpplekaka med vaniljsås</p>
          </div>
          <div className="w-6 mx-auto my-8">
            <div className="max-w-full ">
              <Ornament />
            </div>
          </div>
          <p className="text-lg mt-4 italic font-bold">
            {" "}
            Tre rätter, 495kr per person
          </p>

          <p className="text-lg mt-2 italic font-bold">
            {" "}
            Färsk hel gås 379:-/kg vikt ca 5kg
          </p>
          <p className="my-4 ">
            Beställ via{" "}
            <a
              href="mailto:info@clemenskott.se"
              title="Boka"
              className="underline"
            >
              info@clemenskott.se
            </a>{" "}
            / 040-22 22 96
            <br />
            Hämtas mellan den 8/11- 17/11.
          </p>
          {/* <p className="text-cherry">
              Desvärre är vår gåsmeny slutsåld för tillfället
            </p> */}
        </div>
      </fieldset>
      <div className="w-full px-24 py-8 -ml-8">
        <div className=" blend-multiply">
          <StaticImage src="../images/gas.jpg" alt="Gås 2022" />
        </div>
      </div>
    </section>
  )
}
